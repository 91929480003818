import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const Faq = () => {
  const { faq } = useStaticQuery(graphql`
    query {
      faq: allMdx(
        filter: {
          frontmatter: {
            category: { eq: "よくある質問" }
            sticky: { eq: true }
          }
        }
        limit: 6
        sort: { order: ASC, fields: fields___order }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            sub_category
          }
        }
      }
    }
  `)
  return (
    <div className="faq wrapper">
      <h2>よくある質問</h2>
      <div className="faq_container">
        <ul>
          {faq.nodes.map((article, idx) => (
            <li key={idx}>
              <Link to={article.fields.slug}>{article.frontmatter.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Faq
