import React from 'react'
import PropTypes from 'prop-types'
import ArticleListItem from '@/components/articleListItem'
import SeeMoreLink from '@/components/list/seeMoreLink'

const ArticleList = ({ articles, limit, className, category }) => {
  if (!articles) return null
  return (
    <ul className={className ? `${className}_ullst` : ''}>
      {articles.slice(0, limit).map((article) => (
        <ArticleListItem
          key={article.id}
          title={article.frontmatter.title}
          to={article.fields.slug}
          className={className}
        />
      ))}
      {category !== '' && articles.length > limit ? (
        <SeeMoreLink to={`/categories/${category}/`} />
      ) : (
        ''
      )}
    </ul>
  )
}

export default ArticleList

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number,
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
}
