import React from 'react'
import PropTypes from 'prop-types'

const PageTitle = ({ title, description }) => {
  return (
    <div className="pageTitle">
      <div className="pageTitle_container">
        <h1>{title}</h1>
        {description}
      </div>
    </div>
  )
}

export default PageTitle

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.element,
}
