import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as styles from './seeMoreLink.module.scss'

const SeeMoreLink = ({ to, children }) => {
  return (
    <div className={styles.cSeeMoreLink}>
      <Link to={to}>{children}</Link>
    </div>
  )
}

export default SeeMoreLink

SeeMoreLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

SeeMoreLink.defaultProps = {
  children: 'もっと見る',
}
