import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const ArticleListItem = ({ to, title, className }) => {
  return (
    <li className={className ? `${className}_lst` : ''}>
      <Link to={to}>
        <span>{title}</span>
      </Link>
    </li>
  )
}

export default ArticleListItem

ArticleListItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
