import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import ArticleList from '@/components/articleList'

const ArticleListWidget = ({ title, category, className }) => {
  const { categories, articles } = useStaticQuery(graphql`
    query {
      categories: allCategoriesJson {
        nodes {
          name
          category
          id
        }
      }
      articles: allMdx(sort: { order: ASC, fields: fields___order }) {
        nodes {
          id
          frontmatter {
            title
            sub_category
            category
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      <h3>
        <span>{title ? title : category}</span>
      </h3>
      {categories.nodes
        .filter((node) => node.category === category)
        .map((node) => {
          const items = articles.nodes.filter(
            (article) => article.frontmatter.sub_category === node.name,
          )
          if (items.length === 0) return null
          return (
            <div key={node.id}>
              <h4>
                <span>{node.name}</span>
              </h4>
              <ArticleList
                articles={items}
                className="bl_guide"
                category={node.name}
                limit={3}
              />
            </div>
          )
        })}
    </div>
  )
}

export default ArticleListWidget

ArticleListWidget.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}
