import React from 'react'
import PropTypes from 'prop-types'

const Toc = ({ items, depth }) => {
  if (items.length === 0) return null

  const _depth = depth || 1

  const List = ({ items, current }) => {
    if (items.length === 0) return null
    if (current > _depth) return null

    return (
      <ul>
        {items.map((item, idx) => (
          <li key={idx} className="toc__list">
            <a href={item.url}>{item.title}</a>
            {item.items && <List items={item.items} current={current + 1} />}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="toc">
      <h3 className="toc__title">【目次】</h3>
      <List items={items} current={1} />
    </div>
  )
}

export default Toc

Toc.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  depth: PropTypes.number,
  current: PropTypes.number,
}
