import React from 'react'
import Layout from '@/components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Seo from '@/components/seo'
import PropTypes from 'prop-types'
import PageTitle from '@/components/pageTitle'
import Faq from '@/components/faq'
import Contact from '@/components/contact'
import ScrollToTopButton from '@/components/scrollToTopButton'
import Toc from '@/components/toc'
import Breadcrumb from '@/components/breadcrumb'
import ArticleListWidget from '@/components/articleListWidget'
import '../styles/markdown.scss'

const rightWidgets = [
  <ArticleListWidget
    key="1"
    className="bl_guideUser"
    category="ユーザー向け機能説明"
    title="ユーザー向け機能説明"
  />,
  <ArticleListWidget
    key="2"
    className="bl_guideAdmin"
    category="管理者向け機能説明"
    title="管理者向け機能説明"
  />,
]

const Post = ({ data }) => {
  const { post, categories } = data
  const category = categories.nodes.find(
    (item) => item.name === post.frontmatter.category
  )
  const breadcrumb = [{ title: post.frontmatter.title }]

  const createBreadcrumb = (category) => {
    if (!category) return

    breadcrumb.unshift({
      title: category.name,
      path: `/categories/${category.name}/`,
    })

    const parent = categories.nodes.find(
      (item) => item.name === category.category
    )

    if (!parent?.category) return

    createBreadcrumb(parent)
  }

  createBreadcrumb(
    categories.nodes.find(
      (item) =>
        item.name ===
        (post.frontmatter.sub_category || post.frontmatter.category)
    )
  )

  const page = {
    title: category.name,
    description: null,
  }

  if (category.category === '操作マニュアル') {
    page.title = post.frontmatter.title
    page.description = (
      <p className={category.icon}>{post.frontmatter.category}</p>
    )
  }

  const ArticleLists = () => {
    if (category.name === '管理者向け機能説明') {
      return (
        <>
          {rightWidgets[1]}
          {rightWidgets[0]}
        </>
      )
    }
    return (
      <>
        {rightWidgets[0]}
        {rightWidgets[1]}
      </>
    )
  }

  return (
    <Layout search={true}>
      <Seo title={post.frontmatter.title} />
      <Breadcrumb items={breadcrumb} />
      <PageTitle title={page.title} description={page.description} />
      <div className="wraper main_contents">
        <main>
          <article>
            {post.frontmatter.toc && (
              <Toc items={post?.tableOfContents?.items || []} depth={2} />
            )}
            <section className="markdown-body">
              {!page.description && <h2>{post.frontmatter.title}</h2>}
              <MDXRenderer>{post.body}</MDXRenderer>
            </section>
          </article>
        </main>
        <aside className="category">
          <ArticleLists />
        </aside>
      </div>
      <Faq />
      <Contact />
      <ScrollToTopButton />
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }),
}

export default Post

export const query = graphql`
  query ($slug: String!) {
    categories: allCategoriesJson {
      nodes {
        category
        name
        icon
        id
      }
    }
    post: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        category
        sub_category
        toc
      }
      tableOfContents
    }
  }
`
