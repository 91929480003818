import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const ScrollToTopButton = () => {
  const [show, setShow] = useState()

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  }, [])

  if (!show) return null

  return (
    <button className="btnPageTop" onClick={scrollToTop}>
      <StaticImage
        placeholder="none"
        alt="pagetop"
        src="../images/icon_pagetop.png"
        layout="fullWidth"
      />
    </button>
  )
}
export default ScrollToTopButton
