import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs_container">
        <ul className="breadcrumbs_container_ullst">
          <li className="breadcrumbs_container_lst">
            <Link to="/">Top Page</Link>
          </li>
          {items.map((item, idx) => {
            return (
              <li key={idx} className="breadcrumbs_container_lst">
                {item.path ? (
                  <Link to={item.path}>{item.title}</Link>
                ) : (
                  item.title
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
}

export default Breadcrumb
