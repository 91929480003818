import React from 'react'

const Contact = () => {
  return (
    <div className="contact wrapper">
      <h2>お問合せ</h2>
      <div className="contact_container">
        <p>サービスについてのご相談・ご質問など、お気軽にお問合せください</p>
        <p>
          <a
            className="contact_btn"
            href="https://agelu.tips/contact/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="contact_btn_text">お問合せフォーム</span>
            <span className="newwin"></span>
          </a>
        </p>
      </div>
    </div>
  )
}

export default Contact
